import { gql, useQuery } from "@apollo/client";
import { User, Driver, QueryResult } from "../types";
import getNodeDataFromEdges from "../utils/get-node-data-from-edges";

export const ME = gql`
  query me {
    me {
      pk
      id
      firstName
      lastName
      email
      phone
      isAdmin
      isDriver
    }
  }
`;

export function useMe() {
  const { data, loading, error, refetch } = useQuery<{ me: User }>(ME);
  const me: User | undefined = data?.me;
  return { me, loading, error, refetch };
}

export const GET_DRIVERS = gql`
  query usersQuery($adminCity: String) {
    usersQuery(isDriver: true, adminCity: $adminCity) {
      edges {
        node {
          id
          pk
          firstName
          lastName
          email
          pk
          driverFridges {
            id
            name
          }
        }
      }
    }
  }
`;

export function useDrivers(adminCity: string) {
  const city =
    adminCity === "City"
      ? null
      : adminCity === "YXE"
      ? 1
      : adminCity === "YQR"
      ? 4
      : 2;
  const { data, loading, error, refetch } = useQuery<{
    usersQuery: QueryResult<Driver>;
  }>(GET_DRIVERS, {
    variables: { adminCity: city },
    fetchPolicy: "cache-and-network",
  });
  const drivers =
    data?.usersQuery.edges && getNodeDataFromEdges(data.usersQuery.edges);
  return { drivers, loading, error, refetch };
}
