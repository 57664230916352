import { useState, useEffect } from "react";
import CityDropdownFilter from "../components/city-dropdown-filter";
import DriverDropdownFilter from "../components/driver-dropdown-filter";
import Loading from "../components/loading";
import LocationItems from "../components/location-item";
import DashboardProvider from "../providers/dashboard.provider";
import { useLocations } from "../services/locations.service";
import { useDrivers } from "../services/user.service";
import { DriversWithIds } from "../types";
import { faSearch } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useRef } from "react";
import { updateFridgesQuery } from "../services/organization.service";
import { NetworkStatus } from "@apollo/client";

export default function AdminLocations() {
  const [cityChanged, setCityChanged] = useState<string>("City");
  const [paginationLoading, setPaginationLoading] = useState(false);
  const [search, setSearch] = useState("");
  const [searchTerm, setSearchTerm] = useState("");
  const placeholder: DriversWithIds = {
    userId: undefined,
    name: "All drivers",
  };
  const [driversChanged, setDriversChanged] =
    useState<DriversWithIds>(placeholder);
  const {
    fridges,
    loading: loadingFridges,
    endCursor,
    fetchMore,
    hasNextPage,
    networkStatus,
  } = useLocations({
    selectedCity: cityChanged,
    selectedDriver: driversChanged.userId,
    search: search,
  });
  const ref = useRef<HTMLDivElement>(null);
  const cities = ["City", "YXE", "YYC", "YQR"];
  const { drivers: currDrivers, loading } = useDrivers(cityChanged);
  const drivers: DriversWithIds[] = currDrivers
    ? [
        placeholder,
        ...currDrivers?.map((e) => {
          return {
            userId: e.id,
            name: `${e.firstName} ${e.lastName ?? ""}`,
            email: e.email,
          };
        }),
      ]
    : [placeholder];

  const onPaginate = async () => {
    if (
      fridges &&
      fridges.length > 0 &&
      networkStatus !== NetworkStatus.fetchMore &&
      hasNextPage &&
      !paginationLoading
    ) {
      setPaginationLoading(true);
      await fetchMore({
        updateQuery: updateFridgesQuery,
        variables: { after: endCursor },
      }).finally(() => setPaginationLoading(false));
    }
  };

  useEffect(() => {
    const delayDebounceFn = setTimeout(() => {
      setSearch(searchTerm);
    }, 1000);
    return () => clearTimeout(delayDebounceFn);
  }, [searchTerm]);

  useEffect(() => {
    const observer = new IntersectionObserver(
      (entries) => {
        entries.forEach((entry) => {
          if (entry.isIntersecting && !loading) {
            onPaginate();
          }
        });
      },
      {
        root: null,
        rootMargin: "0px",
        threshold: 1.0,
      }
    );

    if (ref.current) {
      observer.observe(ref.current);
    }

    return () => {
      if (ref.current) {
        observer.unobserve(ref.current);
      }
    };
  }, [ref, loading, networkStatus, hasNextPage, paginationLoading]);

  return (
    <DashboardProvider>
      <div className="px-4 pb-4">
        <header className="mx-auto max-w-7xl">
          <div className="pt-10 mx-auto">
            <div className="-mb-8 ml-2">
              <FontAwesomeIcon icon={faSearch} className="text-gray-700" />
            </div>
            <input
              onChange={(e) => setSearchTerm(e.target.value)}
              value={searchTerm}
              className="block w-full px-3 rounded-md border-0 bg-white py-2 text-black shadow-sm ring-1 ring-inset ring-white/10 focus:ring-2 focus:ring-inset focus:ring-indigo-500 sm:text-sm sm:leading-6 sm:w-3/4 w-full pl-8"
            ></input>
          </div>
          <div className="flex flex-row pt-4 pb-10 mx-auto max-w-7xl">
            <CityDropdownFilter
              title={cityChanged}
              changed={cityChanged !== "City" ? true : false}
              items={cities}
              width="w-24"
              widthOfDropdown="w-56"
              functionToSend={setCityChanged}
              customMenuTitle="All Locations"
            />
            <div className="pl-3" />
            <DriverDropdownFilter
              title={driversChanged.name}
              changed={driversChanged.name !== "All drivers" ? true : false}
              items={drivers}
              widthOfDropdown="w-56"
              functionToSend={setDriversChanged}
            />
          </div>
          <div className="mx-auto max-w-7xl px-4 sm:px-6 lg:px-8"></div>
        </header>
        <main>
          <div className="mx-auto max-w-7xl">
            {networkStatus === NetworkStatus.loading ||
            networkStatus === NetworkStatus.setVariables ? (
              <Loading />
            ) : fridges && fridges.length > 0 ? (
              <>
                <LocationItems fridges={fridges} />
                <div ref={ref} className="pt-4">
                  {networkStatus === NetworkStatus.fetchMore && <Loading />}
                </div>
              </>
            ) : (
              <div>No data</div>
            )}
          </div>
        </main>
      </div>
    </DashboardProvider>
  );
}
