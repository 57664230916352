import { useMutation } from "@apollo/client";
import { useEffect, useState } from "react";
import CityDropdownFilter from "../components/city-dropdown-filter";
import DriverCard from "../components/driver-card";
import DriverDropdownFilter from "../components/driver-dropdown-filter";
import Loading from "../components/loading";
import DashboardProvider from "../providers/dashboard.provider";
import { UPDATE_FRIDGES, useFridges } from "../services/organization.service";
import { GET_DRIVERS, useDrivers } from "../services/user.service";
import { Driver, DriversWithIds } from "../types";

export default function AdminHome() {
  const [cityChanged, setCityChanged] = useState<string>("City");
  const placeholder: DriversWithIds = {
    userId: undefined,
    name: "All drivers",
  };
  const [driversChanged, setDriversChanged] =
    useState<DriversWithIds>(placeholder);
  const cities = ["City", "YXE", "YYC", "YQR"];
  const { drivers: currDrivers, loading: driversLoading } =
    useDrivers(cityChanged);
  const { fridges, loading: fridgesLoading } = useFridges({
    city:
      cityChanged === "City"
        ? undefined
        : cityChanged === "YXE"
        ? "Saskatoon"
        : cityChanged === "YQR"
        ? "Regina"
        : "Calgary",
    drivers: undefined,
  });
  const [driversToDisplay, setDriversToDisplay] = useState<Driver[]>([]);

  const [updateFridge] = useMutation(UPDATE_FRIDGES);
  const drivers: DriversWithIds[] = currDrivers
    ? [
        placeholder,
        ...currDrivers?.map(({ pk, firstName, lastName, email }) => {
          return {
            userId: pk,
            name: `${firstName} ${lastName ?? ""}`,
            email,
          };
        }),
      ]
    : [placeholder];

  async function handleUpdateDriver({
    driverIds,
    fridgeIds,
  }: {
    driverIds: number[];
    fridgeIds: number[];
  }) {
    try {
      await updateFridge({
        variables: {
          input: { driverIds, fridgeIds },
        },
        refetchQueries: [
          {
            query: GET_DRIVERS,
            variables: { city: cityChanged },
          },
        ],
      });
    } catch (e) {
      console.log(e);
    }
  }

  useEffect(() => {
    const newDrivers =
      driversChanged.userId === undefined
        ? currDrivers
        : currDrivers?.filter((driver) => driver.id === driversChanged.userId);

    setDriversToDisplay(newDrivers ?? []);
    // eslint-disable-next-line
  }, [driversChanged, JSON.stringify(currDrivers)]);

  return (
    <DashboardProvider>
      <div className="px-4 pb-4">
        <header>
          <div className="flex flex-row py-10 mx-auto max-w-7xl">
            <CityDropdownFilter
              title={cityChanged}
              changed={cityChanged !== "City" ? true : false}
              items={cities}
              width="w-24"
              widthOfDropdown="w-56"
              functionToSend={setCityChanged}
              customMenuTitle="All Locations"
            />
            <div className="pl-3" />
            <DriverDropdownFilter
              title={driversChanged.name}
              changed={driversChanged.name !== "All drivers" ? true : false}
              items={drivers}
              width=""
              widthOfDropdown="w-56"
              functionToSend={setDriversChanged}
            />
          </div>
        </header>
        <main className="mx-auto max-w-7xl">
          {driversLoading || fridgesLoading ? (
            <Loading />
          ) : (
            <div className="grid sm:grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4">
              {driversToDisplay?.map((driver) => (
                <DriverCard
                  key={driver.id}
                  driver={driver}
                  fridges={fridges!}
                  handleUpdateDriver={handleUpdateDriver}
                />
              ))}
            </div>
          )}
        </main>
      </div>
    </DashboardProvider>
  );
}
